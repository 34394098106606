import React from "react"

import Layout from "~/components/layout"
import SEO from "~/components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO seo={{ title: "找不到页面" }} />
    <h1 className="mb-5">找不到页面</h1>
    <Link to={`/`}>回到主页</Link>
  </Layout>
)

export default NotFoundPage
